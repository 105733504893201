import { ActionTree } from 'vuex'

import { RootState } from '../types'
import { CartState } from './types'

import {
  cartItemIsAnUpsellingReference,
  extractUpsellItems,
  selectCurrentUpsellingGroup,
} from './upsell'

import api from '@/services'

import Attribute from '@/entities/cart/Attribute'

import { LS_CART_ID } from '@/const'

import ShopifyBase64 from '@/utils/shopifyBase64'
import { Cart, CartLine } from '@/services/types/cart'
import {
  AnyCartReturnedError,
  CartLineInput,
  CartLineUpdateInput,
} from '@/services/cartService'

interface IUpdateItemDeliveryArgs {
  products: {
    id: string
    metafield: {
      value: string
    }
  }[]
  getDeliveryText: Function
}

export const actions: ActionTree<CartState, RootState> = {
  getCheckout({ dispatch, commit }, id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('LOADING', true)
      api.cartService
        .get(id, this.$i18n.locale)
        .then((cart) => {
          if (!cart) {
            localStorage.removeItem(LS_CART_ID)
            commit('CART', null)
          } else if (cart) {
            commit('CART', cart)
            dispatch('updateUpsellItems')
          }

          resolve()
        })
        .catch((err: any) => {
          if (err instanceof AnyCartReturnedError) {
            localStorage.removeItem(LS_CART_ID)
            commit('CART', null)
            resolve()
          } else {
            // eslint-disable-next-line no-console
            console.error(err)
            commit('ERROR', err)
            reject(err)
          }
        })
        .finally(() => {
          commit('LOADING', false)
        })
    })
  },
  addItems({ dispatch, state, commit }, items: CartLineInput[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('LOADING', true)

      if (!state.cart) {
        return api.cartService
          .create(items, this.$i18n.locale)
          .then((cart) => {
            if (cart) {
              localStorage.setItem(LS_CART_ID, cart.id)

              commit('CART', cart)
              dispatch('updateUpsellItems')

              resolve()
            }
          })
          .catch((err: any) => {
            commit('ERROR', err)
            reject(err)
          })
          .finally(() => {
            commit('LOADING', false)
          })
      }

      // For each item check if already in checkout
      const pp: Promise<Cart>[] = []
      const itemsToAdd: CartLineInput[] = []
      items.forEach((item) => {
        const itemIndex = state.cart
          ? state.cart?.lines.edges.findIndex(
              (i) => i.node.merchandise.id === item.merchandiseId
            )
          : -1

        if (itemIndex > -1) {
          if (state.cart) {
            pp.push(
              api.cartService.updateLines(
                state.cart.id,
                [
                  {
                    id: state.cart.lines.edges[itemIndex].node.id,
                    quantity:
                      state.cart.lines.edges[itemIndex].node.quantity + 1,
                    attributes: [
                      ...state.cart.lines.edges[itemIndex].node.attributes,
                      ...(item.attributes?.filter((a) =>
                        [
                          '_delivery',
                          '_delivery_date',
                          '_delivery_last_updated_at',
                        ].includes(a.key)
                      ) || []),
                    ],
                  },
                ],
                this.$i18n.locale
              )
            )
          }
        } else {
          itemsToAdd.push(item)
        }
      })

      return Promise.all(pp)
        .then((_) => {
          if (state.cart) {
            return api.cartService
              .addLines(state.cart.id, itemsToAdd, this.$i18n.locale)
              .then((cart) => {
                if (cart) {
                  commit('CART', cart)
                }

                dispatch('updateUpsellItems')
                resolve()
              })
              .catch((err: any) => {
                commit('ERROR', err)
                reject(err)
              })
              .finally(() => {
                commit('LOADING', false)
              })
          }
        })
        .catch((err: any) => {
          commit('ERROR', err)
          reject(err)
        })
    })
  },
  updateItem(
    { dispatch, state, commit },
    item: { id: string; quantity: number }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (state.cart) {
        return api.cartService
          .updateLines(state.cart.id, [item], this.$i18n.locale)
          .then((cart) => {
            if (cart) {
              commit('CART', cart)
            }

            dispatch('updateUpsellItems')

            resolve()
          })
          .catch((err: any) => {
            // eslint-disable-next-line no-console
            console.error(err)
            commit('ERROR', err)
            reject(err)
          })
          .finally(() => {
            commit('LOADING', false)
          })
      }

      resolve()
    })
  },
  updateCartItemDelivery({ state, commit }, args: IUpdateItemDeliveryArgs) {
    return new Promise<void>((resolve, reject) => {
      const cartItemsToUpdate: CartLineUpdateInput[] = []
      for (const d of args.products) {
        const id = ShopifyBase64.getId(d.id)
        const deliveryDate = new Date(d.metafield.value)

        let itemsToUpdate: CartLine[] = []
        if (state.cart && state.cart.lines.edges) {
          itemsToUpdate = state.cart.lines.edges
            .map((cl) => cl.node)
            .filter(
              (i: CartLine) =>
                ShopifyBase64.getId(i.merchandise.product.id) === id
            )
        }

        for (const itemToUpdate of itemsToUpdate) {
          if (itemToUpdate && !isNaN(deliveryDate.getTime())) {
            const attributeDeliveryIndex = itemToUpdate.attributes.findIndex(
              (c: Attribute) => c.key === '_delivery'
            )
            const attributeDeliveryDateIndex =
              itemToUpdate.attributes.findIndex(
                (c: Attribute) => c.key === '_delivery_date'
              )

            if (
              !itemToUpdate.attributes[attributeDeliveryIndex] ||
              !itemToUpdate.attributes[attributeDeliveryDateIndex] ||
              deliveryDate.toJSON() !==
                itemToUpdate.attributes[attributeDeliveryDateIndex].value
            ) {
              const customAttributes = itemToUpdate.attributes

              if (customAttributes[attributeDeliveryIndex]) {
                customAttributes[attributeDeliveryIndex].value =
                  args.getDeliveryText(deliveryDate)
              } else {
                customAttributes.push({
                  key: '_delivery',
                  value: args.getDeliveryText(deliveryDate),
                })
              }

              if (customAttributes[attributeDeliveryDateIndex]) {
                customAttributes[attributeDeliveryDateIndex].value =
                  deliveryDate.toJSON()
              } else {
                customAttributes.push({
                  key: '_delivery_date',
                  value: deliveryDate.toJSON(),
                })
              }

              if (
                customAttributes.some(
                  (c: Attribute) => c.key === '_delivery_last_updated_at'
                )
              ) {
                const lastUpdatedAtIndex = customAttributes.findIndex(
                  (c: Attribute) => c.key === '_delivery_last_updated_at'
                )
                customAttributes[lastUpdatedAtIndex].value = new Date().toJSON()
              } else {
                customAttributes.push({
                  key: '_last_updated_at',
                  value: new Date().toJSON(),
                })
              }

              cartItemsToUpdate.push({
                id: itemToUpdate.id,
                attributes: customAttributes,
              })
            }
          }
        }
      }

      if (cartItemsToUpdate.length > 0 && state.cart) {
        return api.cartService
          .updateLines(state.cart.id, cartItemsToUpdate, this.$i18n.locale)
          .then((cart) => {
            if (cart) {
              commit('CART', cart)
            }

            resolve()
          })
          .catch((err: any) => {
            // eslint-disable-next-line no-console
            console.error(err)
            commit('ERROR', err)
            reject(err)
          })
      }

      resolve()
    })
  },

  removeUnvailableItems({ state, commit }) {
    return new Promise<void>((resolve, reject) => {
      if (
        state.cart &&
        state.cart.lines.edges &&
        state.cart.lines.edges.length > 0
      ) {
        const itemsToRemove = state.cart.lines.edges
          .filter((i) => i.node.quantity === 0)
          .map((i) => i.node.id)

        if (itemsToRemove.length > 0) {
          return api.cartService
            .removeLines(state.cart.id, itemsToRemove, this.$i18n.locale)
            .then((cart) => {
              if (cart) {
                commit('CART', cart)
              }

              resolve()
            })
            .catch((err: any) => {
              // eslint-disable-next-line no-console
              console.error(err)
              commit('ERROR', err)
              reject(err)
            })
        }
      }
    })
  },

  updateUpsell({ state, commit }) {
    const upsell: any = {
      has:
        state.cart?.lines.edges.some((e) =>
          cartItemIsAnUpsellingReference(state.upsell.groups, e.node)
        ) || state.upsell.groups.some((g) => g.canCompleteList),
    }

    if (upsell.has) {
      const g = state.upsell.groups.sort((a, b) => {
        if (a.context === this.app.router?.currentRoute.params.context) {
          return -1
        }

        if (b.context === this.app.router?.currentRoute.params.context) {
          return 1
        }

        return a.weight - b.weight
      })

      upsell.group = selectCurrentUpsellingGroup(
        state.cart,
        g,
        this.app.router?.currentRoute.params.context as 'h' | 'f'
      )

      if (upsell.group) {
        upsell.list = extractUpsellItems(
          state.cart,
          g,
          upsell.group,
          this.app.router?.currentRoute.params.context as 'h' | 'f'
        )
      }
    }

    commit('SET_UPSELL', upsell)
  },
  updateUpsellItems({ state, commit, dispatch }) {
    dispatch('updateUpsell')
    if (state.upsell.has) {
      const itemsToLoad = state.upsell.list
        .filter((i: any) => state.upsell.items[i.id] === undefined)
        .map((i: any) => i.id)

      if (itemsToLoad.length) {
        commit('LOADING_UPSELLING', true)

        api.productService
          .getShopifyProductsForCartItem(itemsToLoad, this.$i18n.locale)
          .then((items) => {
            for (const item of items) {
              commit('ADD_UPSELL_ITEM', {
                id: ShopifyBase64.getId(item.id),
                item,
              })
            }
          })
          .catch((err: any) => {
            // eslint-disable-next-line no-console
            console.error(err)
            commit('ERROR', err)
          })
          .finally(() => {
            commit('LOADING_UPSELLING', false)
          })
      }
    }
  },
  addAttribute({ state, commit }, attribute: { key: string; value: string }) {
    if (state.cart) {
      return api.cartService
        .updateAttributes(state.cart?.id, [attribute], this.$i18n.locale)
        .then((cart) => {
          commit('SET_CHECKOUT_ATTRIBUTES', cart.attributes)
        })
    }
  },
}
