import { AuthState } from './types'
import { CustomerAccessToken } from '@/entities/Customer'

export const state: AuthState = {
  authenticated: null,
  customer: null,
  token: new CustomerAccessToken({}),
  loading: false,
  errors: [],
}
