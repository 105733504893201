export const cartQraphQl = `
  id
  createdAt
  updatedAt
  checkoutUrl
  lines(first: 100) {
    edges {
      node {
        id
        quantity
        cost {
          amountPerQuantity {
            amount
            currencyCode
          }
          compareAtAmountPerQuantity {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
            currencyCode
          }
        }
        attributes {
          key
          value
        }
        merchandise {
          ... on ProductVariant {
            id
            sku
            title
            image {
              url: transformedSrc(crop: CENTER, maxWidth: 86, maxHeight: 86, scale: 2)
            }
            selectedOptions {
              name
              value
            }
            product {
              id
              title
              handle
              productType
              vendor
            }
          }
        }
      }
    }
  }
  attributes {
    key
    value
  }
  cost {
    totalAmount {
      amount
      currencyCode
    }
    subtotalAmount {
      amount
      currencyCode
    }
    totalTaxAmount {
      amount
      currencyCode
    }
    totalDutyAmount {
      amount
      currencyCode
    }
  }
  deliveryGroups(first: 100) {
    edges {
      node {
        id
        selectedDeliveryOption {
          title
          code
          estimatedCost {
            amount
            currencyCode
          }
        }
      }
    }
  }
`
