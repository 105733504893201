import { MutationTree } from 'vuex'

import { CartState } from './types'

import { IUpsellingGroupLink, IUpsellingGroupProducts } from '@/const'
import { Cart, CartAttribute } from '@/services/types/cart'

export const mutations: MutationTree<CartState> = {
  CART(state: CartState, cart: Cart) {
    state.cart = cart
  },
  SET_CHECKOUT_ATTRIBUTES(state: CartState, attributes: CartAttribute[]) {
    if (state.cart) {
      state.cart.attributes = attributes
    }
  },
  SET_UPSELL(
    state: CartState,
    upsell: {
      has: boolean
      group?: IUpsellingGroupProducts | IUpsellingGroupLink
      list?: any[]
    }
  ) {
    state.upsell.has = upsell.has
    state.upsell.group = upsell.group || null
    state.upsell.list = upsell.list || []
  },
  ADD_UPSELL_ITEM(state: CartState, el: { id: string; item: any }) {
    state.upsell.items[el.id] = el.item
  },
  LOADING(state: CartState, loading: boolean) {
    state.loading = loading
  },
  LOADING_UPSELLING(state: CartState, loading: boolean) {
    state.loadingUpselling = loading
  },
  ERROR(state: CartState, error: Error) {
    state.error = error.message
  },
  CLEAR_ERROR(state: CartState) {
    state.error = ''
  },
}
