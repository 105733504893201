import { cartQraphQl } from './graphql/cart'
import { Cart } from './types/cart'

export interface CartLineInput {
  id?: string
  merchandiseId: string
  quantity: number
  attributes?: { key: string; value: string }[]
}

export interface CartLineUpdateInput {
  id: string
  quantity?: number
  attributes?: { key: string; value: string }[]
}

export class AnyCartReturnedError extends Error {
  constructor() {
    super('Any cart was returned')
    this.name = 'AnyCartReturnedError'
  }
}

class CartService {
  private headers: any
  private baseUrl: string

  constructor() {
    if (!process.env.SHOPIFY_URL) {
      throw new Error('Missing env var SHOPIFY_URL')
    }
    if (!process.env.SHOPIFY_API_VERSION) {
      throw new Error('Missing env var SHOPIFY_API_VERSION')
    }
    if (!process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN) {
      throw new Error('Missing env var SHOPIFY_STOREFRONT_ACCESS_TOKEN')
    }

    this.baseUrl = `${process.env.SHOPIFY_URL}/api/${process.env.SHOPIFY_API_VERSION}/graphql.json`
    this.headers = {
      'X-Shopify-Storefront-Access-Token': `${process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  async create(lines: CartLineInput[], locale: string): Promise<Cart> {
    try {
      const response = await fetch(this.baseUrl, {
        method: 'POST',
        body: JSON.stringify({
          query: `mutation ($input: CartInput!) {
              cartCreate(input: $input) {
                cart {
                  ${cartQraphQl}
                }
              }
            }`,
          variables: {
            input: {
              lines,
            },
          },
        }),
        headers: { ...this.headers, 'Accept-Language': locale.substring(0, 2) },
      })

      if (response.status !== 200) {
        throw new Error(
          `unexpected error while creating cart with status ${response.statusText}`
        )
      }

      const json = await response.json()

      if (json.errors) {
        throw new Error(
          `errors while creating cart, ${JSON.stringify(json.errors)}`
        )
      }
      if (!json.data.cartCreate.cart) {
        throw new AnyCartReturnedError()
      }

      return json.data.cartCreate.cart as Cart
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async get(id: string, locale: string): Promise<Cart> {
    try {
      const response = await fetch(this.baseUrl, {
        method: 'POST',
        body: JSON.stringify({
          query: `query {
            cart(id: "${id}") {
              ${cartQraphQl}
            }
          }`,
        }),
        headers: { ...this.headers, 'Accept-Language': locale.substring(0, 2) },
      })

      if (response.status !== 200) {
        throw new Error(
          `unexpected error while retrieving cart with status ${response.statusText}`
        )
      }

      const json = await response.json()
      if (!json.data.cart) {
        throw new AnyCartReturnedError()
      }

      return json.data.cart as Cart
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addLines(
    id: string,
    lines: CartLineInput[],
    locale: string
  ): Promise<Cart> {
    try {
      const response = await fetch(this.baseUrl, {
        method: 'POST',
        body: JSON.stringify({
          query: `mutation ($id: ID!, $lines: [CartLineInput!]!) {
            cartLinesAdd(cartId: $id, lines: $lines) {
              cart {
                ${cartQraphQl}
              }
            }
          }`,
          variables: {
            id,
            lines,
          },
        }),
        headers: { ...this.headers, 'Accept-Language': locale.substring(0, 2) },
      })

      if (response.status !== 200) {
        throw new Error(
          `unexpected error while adding items to cart with status ${response.statusText}`
        )
      }

      const json = await response.json()

      if (json.errors) {
        throw new Error(
          `errors while adding items to cart, ${JSON.stringify(json.errors)}`
        )
      }
      if (!json.data.cartLinesAdd.cart) {
        throw new Error('any cart returned')
      }

      return json.data.cartLinesAdd.cart as Cart
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async removeLines(
    id: string,
    lineIds: string[],
    locale: string
  ): Promise<Cart> {
    try {
      const response = await fetch(this.baseUrl, {
        method: 'POST',
        body: JSON.stringify({
          query: `mutation ($id: ID!, $lineIds: [ID!]!) {
            cartLinesRemove(cartId: $id, lineIds: $lineIds) {
              cart {
                ${cartQraphQl}
              }
            }
          }`,
          variables: {
            id,
            lineIds,
          },
        }),
        headers: { ...this.headers, 'Accept-Language': locale.substring(0, 2) },
      })

      if (response.status !== 200) {
        throw new Error(
          `unexpected error while removing items to cart with status ${response.statusText}`
        )
      }

      const json = await response.json()

      if (!json.data.cartLinesRemove.cart) {
        throw new Error('any cart returned')
      }

      return json.data.cartLinesRemove.cart as Cart
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateLines(
    id: string,
    lines: CartLineUpdateInput[],
    locale: string
  ): Promise<Cart> {
    try {
      const response = await fetch(this.baseUrl, {
        method: 'POST',
        body: JSON.stringify({
          query: `mutation ($id: ID!, $lines: [CartLineUpdateInput!]!) {
            cartLinesUpdate(cartId: $id, lines: $lines) {
              cart {
                ${cartQraphQl}
              }
            }
          }`,
          variables: {
            id,
            lines,
          },
        }),
        headers: { ...this.headers, 'Accept-Language': locale.substring(0, 2) },
      })

      if (response.status !== 200) {
        throw new Error(
          `unexpected error while updating cart lines with status ${response.statusText}`
        )
      }

      const json = await response.json()

      if (json.errors) {
        throw new Error(
          `errors while updating cart lines, ${JSON.stringify(json.errors)}`
        )
      }

      return json?.data?.cartLinesUpdate?.cart as Cart
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateAttributes(
    id: string,
    attributes: { key: string; value: string }[],
    locale: string
  ): Promise<Cart> {
    try {
      const response = await fetch(this.baseUrl, {
        method: 'POST',
        body: JSON.stringify({
          query: `mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
            cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
              cart {
                ${cartQraphQl}
              }
              userErrors {
                field
                message
              }
            }
          }
          `,
          variables: {
            attributes,
            cartId: id,
          },
        }),
        headers: { ...this.headers, 'Accept-Language': locale.substring(0, 2) },
      })

      if (response.status !== 200) {
        throw new Error(
          `unexpected error while updating cart attributes with status ${response.statusText}`
        )
      }

      const json = await response.json()

      if (json.errors) {
        throw new Error(
          `errors while updating cart attributes, ${JSON.stringify(
            json.errors
          )}`
        )
      }

      return json.data.cartAttributesUpdate.cart as Cart
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new CartService()
