import { GetterTree } from 'vuex'

import { RootState } from '../types'
import { CartState } from './types'

export const getters: GetterTree<CartState, RootState> = {
  cart(state: CartState) {
    return state.cart
  },
  countItems(state: CartState): number {
    return state.cart
      ? state.cart.lines.edges.reduce(
          (sum, e) => sum + (e.node.quantity || 0),
          0
        )
      : 0
  },
  upsell(state: CartState) {
    return state.upsell
  },
  isLoading(state: CartState): boolean {
    return state.loading
  },
  isLoadingUpselling(state: CartState): boolean {
    return state.loadingUpselling
  },
  error(state: CartState): string {
    return state.error
  },
}
