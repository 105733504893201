import {
  UPSELLING_TYPES,
  UPSELLING_LIMIT,
  IUpsellingGroupLink,
  IUpsellingGroupProducts,
  IUpsellingProduct,
  upsellingGroup,
} from '@/const'
import { Cart, CartLine } from '@/services/types/cart'
import ShopifyBase64 from '@/utils/shopifyBase64'

export function cartItemIsAnUpsellingReference(
  groups: upsellingGroup[],
  item: CartLine
): boolean {
  let is = false
  for (const group of groups) {
    if (
      group.type === UPSELLING_TYPES.LINK &&
      (group as IUpsellingGroupLink).origins.includes(
        ShopifyBase64.getId(item.merchandise.product.id)
      )
    ) {
      is = true
    } else if (
      group.type === UPSELLING_TYPES.PRODUCTS &&
      (group as IUpsellingGroupProducts).products.some(
        (g) => g.id === ShopifyBase64.getId(item.merchandise.product.id)
      )
    ) {
      is = true
    }
  }

  return is
}

export function selectCurrentUpsellingGroup(
  cart: Cart | null,
  groups: upsellingGroup[],
  context: 'h' | 'f'
) {
  let currentGroup: IUpsellingGroupLink | IUpsellingGroupProducts | null = null

  if (cart) {
    for (const item of cart.lines.edges) {
      for (const group of groups) {
        if (
          group.type === UPSELLING_TYPES.LINK &&
          (group as IUpsellingGroupLink).origins.includes(
            ShopifyBase64.getId(item.node.merchandise.product.id)
          )
        ) {
          if (!currentGroup || currentGroup.weight > group.weight) {
            currentGroup = group
          }
        } else if (
          group.type === UPSELLING_TYPES.PRODUCTS &&
          (group as IUpsellingGroupProducts).products.some(
            (p: IUpsellingProduct) =>
              p.id === ShopifyBase64.getId(item.node.merchandise.product.id)
          )
        ) {
          if (!currentGroup || currentGroup.weight > group.weight) {
            currentGroup = group
          }
        }
      }
    }
  }
  if (!currentGroup) {
    for (const group of groups.sort((a, b) => {
      if (!a.context) {
        return 1
      }

      if (!b.context) {
        return -1
      }

      if (a.context === b.context) {
        return a.weight - b.weight
      }
      return a.context === context ? -1 : 1
    })) {
      if (
        group.type === UPSELLING_TYPES.PRODUCTS &&
        group.canCompleteList &&
        group.context === context
      ) {
        currentGroup = group
        break
      }
    }
  }

  return currentGroup
}

export function extractUpsellItems(
  cart: Cart | null,
  groups: upsellingGroup[],
  group: IUpsellingGroupLink | IUpsellingGroupProducts,
  context: 'h' | 'f'
) {
  let pp: IUpsellingProduct[] = []

  if (group.type === UPSELLING_TYPES.LINK) {
    pp.push((group as IUpsellingGroupLink).target)
  } else if (group.type === UPSELLING_TYPES.PRODUCTS) {
    const filterProducts = (group as IUpsellingGroupProducts).products.filter(
      (p) =>
        !cart?.lines.edges.some(
          (e) => ShopifyBase64.getId(e.node.merchandise.product.id) === p.id
        )
    )
    pp = pp.concat(filterProducts)
  }

  // On retire de la liste les produits qui sont potentiellement déjà dans le panier
  pp = pp.filter(
    (p: IUpsellingProduct) =>
      !cart?.lines.edges.some(
        (e) => ShopifyBase64.getId(e.node.merchandise.product.id) === p.id
      )
  )

  // S'il reste des places, on vient regarder dans les autres groupes si on peut ajouter des produits
  if (group.type === UPSELLING_TYPES.PRODUCTS && pp.length < UPSELLING_LIMIT) {
    const otherGroup = groups
      .sort((a, b) => {
        if (!a.context) {
          return 1
        }

        if (!b.context) {
          return -1
        }

        if (a.context === b.context) {
          return a.weight - b.weight
        }
        return a.context === context ? -1 : 1
      })
      .filter(
        (g) => g.type === group?.type && g !== group && g.canCompleteList
      ) as IUpsellingGroupProducts[]

    let i = 0
    while (otherGroup[i] && pp.length < UPSELLING_LIMIT) {
      const otherProducts = otherGroup[i].products.filter(
        (p) =>
          !cart?.lines.edges.some(
            (e) => ShopifyBase64.getId(e.node.merchandise.product.id) === p.id
          )
      )

      pp = pp.concat(
        otherProducts.filter((p) => !pp.some((i) => i.id === p.id))
      )

      i++
    }
  }

  if (pp.length > UPSELLING_LIMIT) {
    pp = pp.slice(0, UPSELLING_LIMIT)
  }

  return pp
}
