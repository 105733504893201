import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '../types'
import { COUNTRIES } from '@/const'

export interface BehaviorState {
  scroll: boolean
  morphoSelected: { [key: string]: number }
  objectToClose: Function[]
  sliceToHide: string[]
  fixHeader: boolean
  detectedCountry: string | null
}

export const state: BehaviorState = {
  scroll: false,
  morphoSelected: {},
  objectToClose: [],
  sliceToHide: [],
  fixHeader: true,
  detectedCountry: null,
}

export const getters: GetterTree<BehaviorState, RootState> = {
  scroll: (state) => state.scroll,
  morphoSelected: (state) => state.morphoSelected,
  objectToClose: (state) => state.objectToClose,
  sliceToHide: (state) => state.sliceToHide,
  fixHeader: (state) => state.fixHeader,
  detectedCountry: (state) => state.detectedCountry,
}

export const mutations: MutationTree<BehaviorState> = {
  SET_SCROLL: (state: BehaviorState, is: boolean) => {
    state.scroll = is
  },
  SET_MORPHO_SELECTED: (
    state: BehaviorState,
    morpho: { uid: string; index: number }
  ) => {
    state.morphoSelected[morpho.uid] = morpho.index
  },
  DELETE_MORPHO_SELECTED: (state: BehaviorState, uid: string) => {
    delete state.morphoSelected[uid]
  },
  ADD_OBJECT_TO_CLOSE: (state: BehaviorState, f: Function) => {
    state.objectToClose.push(f)
  },
  CLEAN_OBJECT_TO_CLOSE: (state: BehaviorState) => {
    state.objectToClose = []
  },
  ADD_SLICE_TO_HIDE: (state: BehaviorState, slice: string) => {
    state.sliceToHide.push(slice)
  },
  CLEAN_SLICE_TO_HIDE: (state: BehaviorState) => {
    state.sliceToHide = []
  },
  SET_FIX_HEADER: (state: BehaviorState, is: boolean) => {
    state.fixHeader = is
  },
  SET_DETECTED_COUNTRY: (state: BehaviorState, country: string) => {
    state.detectedCountry = country
  },
}

export const actions: ActionTree<BehaviorState, RootState> = {
  closeObjects({ state, commit }) {
    for (const f of state.objectToClose) {
      f()
    }

    commit('CLEAN_OBJECT_TO_CLOSE')
  },
  updateDetectedCountry({ state, commit }) {
    if (state.detectedCountry === null) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

      if (!COUNTRIES[tz]) {
        if (!state.detectedCountry) {
          fetch('http://ip-api.com/json/?fields=status,message,countryCode', {
            method: 'GET',
          })
            .then((response) => response.json())
            .then((data) => {
              commit('SET_DETECTED_COUNTRY', data.countryCode.toLowerCase())
            })
            .catch((err) => {
              if (err.message === 'Failed to fetch') {
                this.$logError.captureException(`ip api Fail to fetch - ${err}`)
                commit('SET_DETECTED_COUNTRY', '')
              }
            })
        }
      } else {
        commit('SET_DETECTED_COUNTRY', COUNTRIES[tz])
      }
    }
  },
}

const namespaced = true

export const behavior: Module<BehaviorState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
