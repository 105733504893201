import { ActionTree } from 'vuex'

import { RootState } from '../types'
import { AuthState } from './types'

import { LS_ACCESS_TOKEN, LS_TOKEN_EXP_DATE } from '@/const'

import Customer, { CustomerAccessToken } from '@/entities/Customer'
import { ErrorLogger } from '@/plugins/errorLogger'

import services from '@/services'
import ShopifyBase64 from '@/utils/shopifyBase64'

interface ICredentials {
  email: String
  password: String
}

interface IActivationParams {
  id: String
  activationToken: String
  password: String
}

export const actions: ActionTree<AuthState, RootState> = {
  initAuthentication({ state, commit, dispatch }): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (state.authenticated === null) {
        const accessToken = localStorage.getItem(LS_ACCESS_TOKEN)
        const tokenExpirationDate = localStorage.getItem(LS_TOKEN_EXP_DATE)
        if (accessToken && tokenExpirationDate) {
          const currentTime = new Date().getTime()
          const tokenExpirationTime = parseInt(tokenExpirationDate)
          if (tokenExpirationTime > currentTime) {
            commit(
              'TOKEN',
              new CustomerAccessToken({ accessToken, tokenExpirationTime })
            )

            commit('AUTHENTICATED', true)

            return dispatch('loadCustomerData', accessToken).then((_) => {
              return resolve(true)
            })
          } else {
            // Access expired
            localStorage.removeItem(LS_ACCESS_TOKEN)
            localStorage.removeItem(LS_TOKEN_EXP_DATE)
          }
        }
      }

      commit('AUTHENTICATED', false)

      return resolve(false)
    })
  },
  connectCustomer(
    { commit, dispatch },
    credentials: ICredentials
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      services.customerService
        .createCustomerAccessToken(credentials.email, credentials.password)
        .then((customerAccessTokenCreate) => {
          const customerErrors = customerAccessTokenCreate.customerUserErrors
          const customerToken = customerAccessTokenCreate.customerAccessToken

          if (customerErrors && customerErrors.length > 0) {
            const apiErrors: Error[] = []
            customerErrors.forEach((err: Error) => {
              apiErrors.push(err)
            })
            commit('ERRORS', apiErrors)
            return reject(new Error('something went wrong'))
          }

          if (customerToken) {
            const expDate = new Date(customerToken.expiresAt)
            const accessToken = new CustomerAccessToken({
              accessToken: customerToken.accessToken,
              expiresAt: expDate.getTime(),
            })

            localStorage.setItem(LS_ACCESS_TOKEN, accessToken.accessToken)
            localStorage.setItem(
              LS_TOKEN_EXP_DATE,
              expDate.getTime().toString()
            )

            commit('TOKEN', accessToken)
            commit('AUTHENTICATED', true)

            return dispatch('loadCustomerData', accessToken.accessToken).then(
              (_) => {
                return resolve()
              }
            )
          }

          // On fait quoi ici ? Reject ? Error spécifique ?
        })
        .catch((err) => {
          sendError(err, 'connectCustomer')
          return reject(err)
        })
    })
  },
  loadCustomerData({ commit, dispatch }, accessToken: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      services.customerService
        .getCustomer(accessToken)
        .then((customer) => {
          if (customer) {
            commit('CUSTOMER', new Customer(customer))

            return resolve()
          }

          // Token expired, on set à deconnecté
          dispatch('disconnectCustomer')
          return reject(new Error('Token seems expired'))
        })
        .catch((err) => {
          sendError(err, 'loadCustomerData')
          reject(err)
        })
    })
  },
  createCustomer(
    { commit, dispatch },
    credentials: ICredentials
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      services.customerService
        .createCustomer(credentials)
        .then((customerCreate) => {
          const customerErrors = customerCreate.customerUserErrors

          if (customerErrors && customerErrors.length > 0) {
            const apiErrors: Error[] = []
            customerErrors.forEach((err: Error) => {
              apiErrors.push(err)
            })
            commit('ERRORS', apiErrors)
            return reject(apiErrors)
          }

          const customer = customerCreate.customer
          if (customer) {
            commit('CUSTOMER', new Customer(customer))
            dispatch('connectCustomer', credentials)
              .then(() => {
                resolve()
              })
              .catch((err) => {
                reject(err)
              })
          }

          // On fait quoi ici ? Reject ? Error spécifique ?
        })
        .catch((err) => {
          sendError(err, 'createCustomer')
          reject(err)
        })
    })
  },
  activateCustomer(
    { commit, dispatch },
    activationParams: IActivationParams
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      services.customerService
        .activateCustomer(
          activationParams.id,
          activationParams.activationToken,
          activationParams.password
        )
        .then((customerActivate) => {
          const customerErrors = customerActivate.customerUserErrors
          if (customerErrors && customerErrors.length > 0) {
            const apiErrors: Error[] = []
            customerErrors.forEach((err: Error) => {
              apiErrors.push(err)
            })
            commit('ERRORS', apiErrors)
            return reject(apiErrors)
          }

          const customer = customerActivate.customer
          const customerToken = customerActivate.customerAccessToken
          if (customer && customerToken) {
            commit('CUSTOMER', new Customer(customer))

            const expDate = new Date(customerToken.expiresAt)
            const accessToken = new CustomerAccessToken({
              accessToken: customerToken.accessToken,
              expiresAt: expDate.getTime(),
            })

            localStorage.setItem(LS_ACCESS_TOKEN, accessToken.accessToken)
            localStorage.setItem(
              LS_TOKEN_EXP_DATE,
              expDate.getTime().toString()
            )

            commit('TOKEN', accessToken)
            commit('AUTHENTICATED', true)

            dispatch('loadCustomerData', accessToken.accessToken)

            return resolve()
          }

          // On fait quoi ici ? Reject ? Error spécifique ?
        })
        .catch((err) => {
          sendError(err, 'activateCustomer')
          reject(err)
        })
    })
  },
  updateToken(
    { commit, dispatch },
    customerAccessToken: CustomerAccessToken
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      const expDate = new Date(customerAccessToken.expiresAt)
      const accessToken = new CustomerAccessToken({
        accessToken: customerAccessToken.accessToken,
        expiresAt: expDate.getTime(),
      })

      localStorage.setItem(LS_ACCESS_TOKEN, accessToken.accessToken)
      localStorage.setItem(LS_TOKEN_EXP_DATE, expDate.getTime().toString())

      commit('TOKEN', accessToken)
      commit('AUTHENTICATED', true)

      return dispatch('loadCustomerData', accessToken.accessToken).then((_) => {
        return resolve()
      })
    })
  },
  disconnectCustomer({ commit, dispatch }): Promise<void> {
    return new Promise<void>((resolve) => {
      localStorage.removeItem(LS_ACCESS_TOKEN)
      localStorage.removeItem(LS_TOKEN_EXP_DATE)

      commit('AUTHENTICATED', false)
      commit('CLEAR_ERRORS')

      dispatch('subscriptions/disconnectEmail', {}, { root: true })

      return resolve()
    })
  },
  addOrderToCustomer({ state, commit }, orders: any) {
    if (state.customer) {
      for (const order of orders) {
        state.customer.orders[ShopifyBase64.getId(order.node.id)] = order.node
      }
      commit('CUSTOMER', state.customer)
    }
  },
}

function sendError(err: Error, functionName: string) {
  if (!err.message.includes('CustomerServiceError')) {
    ErrorLogger.error(err, {
      tags: {
        store: 'auth',
        function: functionName,
      },
    })
  }
}
